import React from 'react';

import moment from "moment";

import { Box } from '@mui/system';
import { Skeleton, Tooltip, Typography } from '@mui/material';
import { useAutomationContext } from 'pages/automations/context';
import { OwnerOrRenterFilter, PropertyTypeFilter } from 'models/radius-mailing/filters';

interface IHailTrace {
  isLoading: boolean
}

const HailTrace: React.FC<IHailTrace> = ({ isLoading }) => {
  const { automationDetail } = useAutomationContext();

  const getPropertyType = (propertype: number | undefined) => {
    if(propertype === undefined) return 'N/A'

    return PropertyTypeFilter[propertype]
  }

  const getHomOwner = (homeOwner: number | undefined) => {
    if(homeOwner === undefined) return 'N/A'

    return OwnerOrRenterFilter[homeOwner]
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', margin: '1rem 0 0 0', minHeight: '300px' }}>

      <Box sx={{ display: 'flex', margin: '1rem 0 0 0' }}>
        <Box sx={{ width: '45%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>Name</Typography>
          {
            isLoading ? (
              <Skeleton width={'200px'} height={20} />
            ) : (
              <Tooltip title={automationDetail?.name ?? ''}>
                <Typography variant='h5' sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'black', maxWidth: '200px' }}>{automationDetail?.name}</Typography>
              </Tooltip>
            )
          }
        </Box>
        <Box sx={{ width: '33%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>ID</Typography>
          {
            isLoading ? (
              <Skeleton width={'80px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ color: 'black' }}>{automationDetail?.id}</Typography>
            )
          }
        </Box>
        <Box sx={{ width: '25%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>Status</Typography>
          {
            isLoading ? (
              <Skeleton width={'80px'} height={20} />
            ) : (
              <Box>
                <Typography variant='h5' sx={{ color: 'black' }}>{automationDetail?.status}</Typography>
              </Box>
            )
          }
        </Box>
      </Box>

      <Box sx={{ display: 'flex', margin: '1rem 0 0 0' }}>
        <Box sx={{ width: '45%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>Hail Size</Typography>
          {
            isLoading ? (
              <Skeleton width={'30px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ color: 'black' }}>{automationDetail?.hailTrace?.hailSizeMinFilter}</Typography>
            )
          }
        </Box>
        <Box sx={{ width: '33%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>Wind Speed</Typography>
          {
            isLoading ? (
              <Skeleton width={'30px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ color: 'black' }}>{automationDetail?.hailTrace?.windSpeedMinFilter ? automationDetail?.hailTrace?.windSpeedMinFilter + ' MPH' : 'N/A'}</Typography>
            )
          }
        </Box>
        <Box sx={{ width: '25%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>Cards To Send</Typography>
          {
            isLoading ? (
              <Skeleton width={'30px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ color: 'black' }}>{automationDetail?.hailTrace?.cardsToSendPerStormMax ? 'Up to ' + automationDetail?.hailTrace?.cardsToSendPerStormMax : 'N/A'}</Typography>
            )
          }
        </Box>
      </Box>
      
      <Box sx={{ display: 'flex', margin: '1rem 0 0 0'}}>
        <Box sx={{ width: '45%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>Property Type</Typography>
          {
            isLoading ? (
              <Skeleton width={'120px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ color: 'black' }}>{getPropertyType(automationDetail?.hailTrace?.propertyTypeFilter)}</Typography>
            )
          }
        </Box>
        <Box sx={{ width: '33%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>Home Owner</Typography>
          {
            isLoading ? (
              <Skeleton width={'120px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ color: 'black' }}>{ getHomOwner(automationDetail?.hailTrace?.homeOwnerFilter)}</Typography>
            )
          }
        </Box>
        <Box sx={{ width: '25%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>Total Structures</Typography>
          {
            isLoading ? (
              <Skeleton width={'30px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ color: 'black' }}>{automationDetail?.hailTrace?.totalStructures}</Typography>
            )
          }
        </Box>
      </Box>
      <Box sx={{ display: 'flex', margin: '1rem 0 0 0'}}>
        <Box sx={{ width: '45%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>Sending Period Range</Typography>
          {
            isLoading ? (
              <Skeleton width={'80px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ color: 'black' }}>{automationDetail?.hailTrace?.stopSendingAtDate ? moment(automationDetail?.hailTrace?.stopSendingAtDate).format('MM/DD/YY') : ''}</Typography>
            )
          }
        </Box>
    </Box>

    </Box>
  );
}

export default HailTrace;