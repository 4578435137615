import { useState } from 'react';

import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Fab, Tooltip, Typography, Box, ClickAwayListener, ListItemButton, ListItemText } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Order } from 'models/order';
import OrderService from 'services/order';
import { Template } from 'models/template';
import TemplateService from 'services/template';
import { useGlobal } from 'context/global-context';
import NewSmartAutomationModal from 'components/new-smart-automation-modal';

import './styles.scss';

export default function SidebarCreateItem() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setShowLoader } = useGlobal();

  const [showTooltip, setShowTooltip] = useState(false);
  const [showSmartAutomationModal, setShowSmartAutomationModal] = useState<boolean>(false);

  const { postOrder } = OrderService();
  const { postTemplate } = TemplateService();

  const { mutate: createOrder } = useMutation({
    mutationFn: () => postOrder({ ...new Order(0) }),
    onMutate: () => setShowLoader(true),
    onSuccess: (data) => {
      setShowLoader(false);
      navigate(`/order/${data.id}`);
      queryClient.invalidateQueries({ queryKey: ['orders'] });
    },
  });

  const { mutate: createTemplate } = useMutation({
    mutationFn: () => postTemplate({ ...new Template(0) }),
    onMutate: () => setShowLoader(true),
    onSuccess: (data) => {
      setShowLoader(false);
      setShowTooltip(false);
      navigate(`/template/${data.id}`);
      queryClient.invalidateQueries({ queryKey: ['templates'] });
    },
  });

  const handleCreateSmartAutomation = () => {
    setShowTooltip(false);
    setShowSmartAutomationModal(true);
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip
            open={showTooltip}
            title={
              <Box>
                <ListItemButton
                  onClick={() => {
                    setShowTooltip(false);
                    createOrder();
                  }}
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '3px',
                    borderRadius: 1,
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                >
                  <ListItemText
                    primary="New Order"
                    primaryTypographyProps={{
                      variant: 'caption',
                      sx: { fontWeight: 'normal', fontSize: '14px' },
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    setShowTooltip(false);
                    handleCreateSmartAutomation();
                  }}
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderRadius: 1,
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                >
                  <ListItemText
                    primary="New Radius Mail"
                    primaryTypographyProps={{
                      variant: 'caption',
                      sx: { fontWeight: 'normal', fontSize: '14px' },
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    setShowTooltip(false);
                    handleCreateSmartAutomation();
                  }}
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderRadius: 1,
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                >
                  <ListItemText
                    primary="New Automation"
                    primaryTypographyProps={{
                      variant: 'caption',
                      sx: { fontWeight: 'normal', fontSize: '14px' },
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    setShowTooltip(false);
                    createTemplate();
                  }}
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '3px',
                    borderRadius: 1,
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                >
                  <ListItemText
                    primary="New Template"
                    primaryTypographyProps={{
                      variant: 'caption',
                      sx: { fontWeight: 'normal', fontSize: '14px' },
                    }}
                  />
                </ListItemButton>
              </Box>
            }
            sx={{ display: 'flex', justifyContent: 'center' }}
            placement="bottom"
            arrow
            enterDelay={0}
            leaveDelay={0}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: '#fff',
                  minWidth: '210px !important',
                  color: '#344767',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  borderRadius: 1,
                },
              },
              arrow: { sx: { color: '#fff' } },
            }}
          >
            <Fab
              size="medium"
              aria-label="add"
              sx={{
                mt: 2.5,
                mx: 'auto',
                color: showTooltip ? '#FFFFFF' : '#F05A2A',
                background: showTooltip ? '#F05A2A' : '#FFFFFF',
                transition: '1s ease all',
                '&:hover': {
                  background: '#F05A2A',
                  transition: '1s ease all',
                  animation: 'pulse 2s ease-out infinite',
                  color: '#FFFFFF',
                },
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 2px 4px 0px inset',
              }}
              onClick={() => setShowTooltip((prev) => !prev)}
            >
              <Add />
            </Fab>
          </Tooltip>
        </Box>
      </ClickAwayListener>
      <Typography
        variant="caption"
        sx={{
          margin: '.3rem auto 25px auto',
          fontWeight: 'bold',
          fontSize: '14px',
          color: '#344767',
        }}
      >
        Create
      </Typography>

      <NewSmartAutomationModal
        open={showSmartAutomationModal}
        setOpen={setShowSmartAutomationModal}
      />
    </>
  );
}
