import { Home, FormatListBulleted, Person, Star, AutoMode, CreditCard, Construction } from '@mui/icons-material';

import HomePage from 'pages/home';
import OrdersPage from 'pages/orders';
import MyTemplatesPage from 'pages/my-templates';
import CallTrackingOrderPage from 'pages/call-tracking/order';
import CallTrackingHistoryPage from 'pages/call-tracking/manage-numbers';
import DiscountCodes from 'pages/discount-codes';
import AddressBookPage from 'pages/address-book';
import RecipientSearchHistoryPage from 'pages/recipient-search/history';
import RadiusMailHistoryPage from 'pages/radius-mail/history';
import StormAutomationHistoryPage from 'pages/storm-automation/history';
import SmartAutomationPage from 'pages/smart-automation';
import IntegrationOrdersPage from 'pages/integrations/integration-orders';
import SubscriptionsPage from 'pages/subscriptions';
import Billing from 'pages/settings/billing';
import SmartAutomationSetup from 'pages/smart-automation-setup';
import DoNotMail from 'pages/do-not-mail';

import { RouteType } from './types';

const sidebarRoutes: RouteType[] = [
  {
    icon: <Home />,
    name: 'Home',
    route: '/home',
    component: <HomePage />,
  },
  {
    icon: <FormatListBulleted />,
    name: 'Orders',
    collapse: [
      {
        name: 'Orders',
        route: '/orders',
        component: <OrdersPage />,
      },
    ],
  },
  {
    icon: <Person />,
    name: 'Leads',
    collapse: [
      {
        name: 'Address Book',
        route: '/address-book',
        component: <AddressBookPage />,
      },

      {
        name: 'Recipient Search',
        route: '/recipient-search',
        component: <RecipientSearchHistoryPage />,
      },
      // Data enrichment doesn't exist yet
      {
        name: 'Do Not Mail',
        route: '/do-not-mail',
        component: <DoNotMail />,
      },
    ],
  },
  {
    icon: <AutoMode />,
    name: 'Automations',
    collapse: [
      {
        name: 'Smart Automation',
        route: '/smart-automation',
        component: <SmartAutomationSetup />,
      },
      {
        name: 'Automations',
        route: '/automations',
        component: <SmartAutomationPage />,
      },
      {
        name: 'Storm Automation',
        route: '/storm-automation',
        component: <StormAutomationHistoryPage />,
        hide: true,
      },
      {
        name: 'Radius Mail',
        route: '/radius-mailing',
        component: <RadiusMailHistoryPage />,
      },
      {
        name: 'Integration Orders',
        route: '/integration-orders',
        component: <IntegrationOrdersPage />,
      },
    ],
  },
  {
    icon: <Star />,
    name: 'Goals',
    collapse: [
      {
        name: 'Call Tracking Order',
        route: '/call-tracking/order',
        component: <CallTrackingOrderPage />,
      },
      {
        name: 'Manage Call Tracking',
        route: '/call-tracking/history',
        component: <CallTrackingHistoryPage />,
      },
      {
        name: 'Discount Codes',
        route: '/discount-codes',
        component: <DiscountCodes />,
      },
    ],
  },
  {
    icon: <Construction />,
    name: 'Tools',
    collapse: [
      {
        name: 'Templates',
        route: '/my-templates',
        component: <MyTemplatesPage />,
      },
    ],
  },
  {
    icon: <CreditCard />,
    name: 'Billing',
    collapse: [
      {
        name: 'Billing',
        route: '/settings/billing',
        component: <Billing />,
      },
      {
        name: 'Subscriptions',
        route: '/subscriptions',
        component: <SubscriptionsPage />,
      },
    ],
  },
];

export default sidebarRoutes;
