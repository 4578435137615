import React from 'react';

import { Box } from '@mui/system';
import { List, ListItem, Typography } from '@mui/material';

const Wix: React.FC = () => {
  return (
    <Box sx={{ margin: '1rem 0'}}>
        <Typography variant='body2' sx={{ fontWeight: 'bold'}}>Wix</Typography>
        <List>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1 }}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='body2'>Log in to your Wix account and open your website editor</Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1, margin: '.5rem 0'}}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', gap: 1}}>Click on <b style={{ display: 'flex', alignItems: 'center', gap: 4}}> Settings in the left-hand-menu</b></Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1 }}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='body2'>Click on <b>Traking & Analytics</b></Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1, margin: '.5rem 0'}}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='body2' sx={{ display: 'flex', gap: 1}}>Click <b>+ New Tool</b> and select <b>Custom</b></Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1, margin: '.5rem 0'}}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', gap: 1}}>Paste the provided script into the code snippet box and set location to <b style={{ display: 'flex', alignItems: 'center', gap: 4}}> Head</b></Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1, margin: '.5rem 0'}}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', gap: 1}}>Click <b style={{ display: 'flex', alignItems: 'center', gap: 4}}> Apply</b></Typography>
            </ListItem>
        </List>
    </Box>
  );
}

export default Wix;