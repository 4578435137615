import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';
import MDButton from 'material-ui/components/MDButton';
import useSourceInstallRequest from 'hooks/useSourceInstallRequest';
import useDataSourcesService from 'services/data-sources';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import Loader from 'components/loader';

interface ShopifyModalProps {
  open: boolean;
  onClose: () => void;
}

const ShopifyModal = ({ open, onClose }: ShopifyModalProps) => {
  const queryClient = useQueryClient();
  const { setInstallSource } = useSourceInstallRequest();
  
  const { getShopifyStores, revokeDataSource } = useDataSourcesService();
  const [revokeId, setRevokeId] = useState<string>('');

  const { data: { payload: stores = [] } = {}, isLoading } = useQuery({
    queryKey: ['getShopifyStores'],
    queryFn: () => getShopifyStores(),
    refetchOnWindowFocus: false,
  });

  const { mutate: revoke, isPending } = useMutation({
    mutationFn: () => revokeDataSource('shopify', revokeId),
    onSuccess: () => {
      if (stores.length > 1) queryClient.invalidateQueries({ queryKey: ['getShopifyStores'] });
      else queryClient.invalidateQueries({ queryKey: ['getAllDataSources'] });
      setRevokeId('')
      onClose();
    },
  });

  useEffect(() => {
    if (revokeId) revoke();
  }, [revoke, revokeId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <DialogTitle>Shopify</DialogTitle>
        <Stack spacing={1}>
          {isLoading ? (
            <Stack alignItems={'center'}>
              <Loader />
            </Stack>
          ) : (
            stores.map((store, index) => (
              <>
                <Box display="flex" justifyContent={'space-between'}>
                  <Typography fontSize={16}>{store.name}</Typography>
                  <MDButton size="small" color="secondary" onClick={() => setRevokeId(store.id)} sx={{ width: 85 }}>
                    {isPending && revokeId === store.id ? <CircularProgress size={18} color="inherit" /> : 'Revoke'}
                  </MDButton>
                </Box>
                {index !== stores.length - 1 && <Divider light />}
              </>
            ))
          )}
          <MDButton fullWidth onClick={() => setInstallSource('shopify')}>
            Setup New Store
          </MDButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ShopifyModal;
