import { ChangeEvent, useState } from 'react';
import { Field, FieldInputProps, FieldProps, Form, FormikProps, FormikValues, useFormikContext } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Collapse,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import FormikErrorMessage from 'components/formik-error-message';
import { ILeadRevealSourcePayload } from 'services/data-sources/data-sources.types';
import MDButton from 'material-ui/components/MDButton';
import TerminalCodeBlock from 'components/terminal-code-block';
import useDataSourcesService from 'services/data-sources';
import Loader from 'components/loader';
import MonthlyLeadsField from '../monthly-leads-field';
import AccordionTitle from '../accordion-title';
import LeadRevealPaymentAccordion from './lead-reveal-payment-accordion';

interface LeadRevealFinishFormProps extends FormikProps<ILeadRevealSourcePayload> {
  onClose: () => void;
}

export default function LeadRevealFinishForm({
  values,
  touched,
  initialValues,
  setFieldValue,
  setTouched,
  onClose,
}: LeadRevealFinishFormProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { submitForm } = useFormikContext();
  const { createLeadRevealSource, testLeadRevealConnection } = useDataSourcesService();

  const [warning, setWarning] = useState<string>('');
  const [expanded, setExpanded] = useState<string>('');
  const [testError, setTestError] = useState<boolean>(false);
  const [showGenericError, setShowGenericError] = useState<boolean>(false);
  const [apiErrorResponse, setApiErrorResponse] = useState<string>('');
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '');

  const { mutate: testConnection, isPending: isTesting } = useMutation({
    mutationFn: testLeadRevealConnection,
    onSuccess: (result) => {
      if (!result.payload || typeof result.payload !== 'boolean') return setTestError(true);
      setFieldValue('hasInstalledTagManagerCorrectly', true);
      setExpanded('');
      if (result.hasWarnings && result.warnings?.length) setWarning(result.warnings[0]);
      else submitForm();
    },
  });

  const {
    data: updatedSource,
    mutate: createLeadReveal,
    isPending,
  } = useMutation({
    mutationFn: createLeadRevealSource,
    onSuccess: ({ payload }) => {
      if (payload.projectId) {
        return queryClient.invalidateQueries({ queryKey: ['getLeadRevealSource'] });
      }
      var error = payload as any;
      setApiErrorResponse(error.Message);
      setShowGenericError(true);
      setExpanded('');
    },
  });

  const projectTag = updatedSource?.payload?.projectId || initialValues?.projectId;

  const handleTagTestAccordionChange = () => {
    if (expanded === 'TagTest') setExpanded('');
    else if ((values.useServiceAccount || values.accountName) && values.websiteUrl) {
      if (!projectTag) createLeadReveal(JSON.stringify(values));
      setExpanded(expanded === 'TagTest' ? '' : 'TagTest');
    } else setTouched({ ...touched, accountName: true, websiteUrl: true });
  };

  const handleRequiredToTestFieldChange = (field: FieldInputProps<FormikValues>, e: ChangeEvent) => {
    if (expanded === 'TagTest') setExpanded('');
    if (values.hasInstalledTagManagerCorrectly) setFieldValue('hasInstalledTagManagerCorrectly', false);
    field.onChange(e);
  };

  const handleWebsiteUrlChange = (value: string) => {
    if (expanded === 'TagTest') setExpanded('');
    if (values.hasInstalledTagManagerCorrectly) setFieldValue('hasInstalledTagManagerCorrectly', false);
    const url = value.startsWith('http') ? value : `https://${value}`;
    setFieldValue('websiteUrl', url);
  };

  return (
    <Form>
      <Grid container spacing={1}>
        {!values.useServiceAccount && (
          <Field name="accountName">
            {({ field, meta }: FieldProps) => (
              <Grid item xs={12} sm>
                <Typography variant="h5">Account</Typography>
                <Select
                  {...field}
                  displayEmpty
                  fullWidth
                  disabled={!!projectTag}
                  error={!!meta.touched && !!meta.error}
                  onChange={(e) => handleRequiredToTestFieldChange(field, e as ChangeEvent)}
                >
                  <MenuItem value="" disabled>
                    Select an option
                  </MenuItem>
                  {initialValues?.accountContainers?.map((p) => (
                    <MenuItem key={p.account.name} value={p.account.name}>
                      {p.account.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormikErrorMessage meta={meta} />
              </Grid>
            )}
          </Field>
        )}
        <Field name="websiteUrl">
          {({ field, meta }: FieldProps) => (
            <Grid item xs={12} sm>
              <Typography variant="h5">Website Url</Typography>
              <TextField
                {...field}
                fullWidth
                disabled={!!projectTag}
                placeholder="e.g. https://example.com"
                error={!!meta.touched && !!meta.error}
                onChange={(e) => handleWebsiteUrlChange(e.target.value)}
              />
              <FormikErrorMessage meta={meta} />
            </Grid>
          )}
        </Field>
        <MonthlyLeadsField />
      </Grid>
      {!values.useServiceAccount && initialValues?.accountContainers?.length === 0 && (
        <Grid container>
          <Alert severity="warning">
            No Google Tag Manager accounts were found. Please go to{' '}
            <a
              href="https://tagmanager.google.com/"
              title="Google Tag Manager"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Tag Manager
            </a>
            , create an account and return to LettrLabs to complete your setup.
          </Alert>
        </Grid>
      )}

      <Typography variant="h5" mt={2} mb={0.5}>
        Enable Requirements
      </Typography>
      {stripePromise && (
        <Field name="hasDefaultCreditCard">
          {(fieldProps: FieldProps) => (
            <Elements stripe={stripePromise}>
              <LeadRevealPaymentAccordion expanded={expanded} setExpanded={setExpanded} {...fieldProps} />
            </Elements>
          )}
        </Field>
      )}
      <Field name="hasActiveSubscription">
        {({ field, meta }: FieldProps) => (
          <Accordion
            elevation={0}
            disableGutters
            disabled={field.value}
            expanded={expanded === 'Subscription'}
            onChange={() => setExpanded(expanded === 'Subscription' ? '' : 'Subscription')}
            sx={{ border: `1px solid ${meta?.touched && meta?.error ? 'red' : '#344767'}` }}
          >
            <AccordionTitle checked={field.value} meta={meta}>
              Active LettrLabs subscription
            </AccordionTitle>
            <AccordionDetails>
              <Alert severity="info">
                <AlertTitle sx={{ fontSize: 18, fontWeight: 'regular', mb: 0 }}>
                  To handle daily incoming leads from your website traffic, activating LeadReveal requires an active
                  subscription.
                </AlertTitle>
              </Alert>
            </AccordionDetails>
            <AccordionActions>
              <MDButton color="secondary" onClick={() => navigate('/subscriptions')}>
                Subscribe!
              </MDButton>
            </AccordionActions>
          </Accordion>
        )}
      </Field>
      <Field name="hasInstalledTagManagerCorrectly">
        {({ field, meta }: FieldProps) => (
          <Accordion
            elevation={0}
            disableGutters
            disabled={field.value}
            expanded={expanded === 'TagTest'}
            onChange={() => handleTagTestAccordionChange()}
            sx={{ border: `1px solid ${meta?.touched && meta?.error ? 'red' : '#344767'}` }}
          >
            <AccordionTitle checked={field.value} meta={meta}>
              Add LeadReveal tags to your website
            </AccordionTitle>
            <AccordionDetails>
              {isPending ? (
                <Box display={'flex'} justifyContent={'center'}>
                  <Loader />
                </Box>
              ) : (
                <>
                  <Typography variant="h5" fontWeight={'normal'} mb={2}>
                    Complete steps 1, 2 and 3 below to complete the LeadReveal setup process.
                  </Typography>
                  <Typography variant="h5" mb={0.5}>
                    1. Add code into your sites Head Code
                  </Typography>
                  <Box component={'ul'} ml={4} mb={1}>
                    <Typography component={'li'} variant="h5" fontWeight={'normal'}>
                      Add as high in the head code as possible
                    </Typography>
                  </Box>
                  <TerminalCodeBlock
                    sx={{ ml: 2 }}
                    code={`<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${projectTag}');</script>
<!-- End Google Tag Manager -->`}
                  />
                  <Typography variant="h5" mt={2} mb={0.5}>
                    2. Add code into your sites Body Code
                  </Typography>
                  <Box component={'ul'} ml={4} mb={1}>
                    <Typography component={'li'} variant="h5" fontWeight={'normal'}>
                      Add as high in the Body code as possible, after the opening body tag
                    </Typography>
                  </Box>
                  <TerminalCodeBlock
                    sx={{ ml: 2 }}
                    code={`<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${projectTag}"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->`}
                  />
                  <Typography variant="h5" mt={2} mb={0.5}>
                    3. After adding the Head and Body code to your site, please publish your website.
                  </Typography>
                  <Typography variant="h5" mt={2} mb={0.5}>
                    4. Verify you've added the Head and Body code to your site.
                  </Typography>
                  <Box ml={2}>
                    <Collapse in={testError}>
                      <Alert severity="warning" onClose={() => setTestError(false)} sx={{ mb: 1 }}>
                        The tracker was not correctly installed yet and couldn't be detected. If you need any help,
                        please contact our live customer support.
                      </Alert>
                    </Collapse>
                    <MDButton
                      color="secondary"
                      sx={{ width: 136 }}
                      disabled={isTesting}
                      onClick={() => testConnection()}
                    >
                      {isTesting ? <CircularProgress color="inherit" size={20} /> : 'Test Website'}
                    </MDButton>
                  </Box>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </Field>
      {warning && (
        <Alert severity="warning" sx={{ mt: 1 }} onClose={() => setWarning('')}>
          {warning}
        </Alert>
      )}
      {showGenericError && (
        <Alert severity="error" sx={{ mt: 1 }} onClose={() => setShowGenericError(false)}>
          {apiErrorResponse}
        </Alert>
      )}
      <Box display={'flex'} gap={1} mt={1}>
        <MDButton fullWidth type="submit">
          Finish Setup
        </MDButton>
        <MDButton color="light" onClick={onClose}>
          Cancel
        </MDButton>
      </Box>
    </Form>
  );
}
