import React from 'react';

import { Box } from '@mui/system';
import { Grid, Skeleton } from '@mui/material';

import MDCard from 'material-ui/components/MDCard';
import MDTypography from 'material-ui/components/MDTypography';
import AutomationsTable from '../Table';

interface IFriendlyFirstAccessPage {
    isLoading: boolean,
}

const MainLoadingState: React.FC<IFriendlyFirstAccessPage> = ({ isLoading }) => {
  return (
    <React.Fragment>
        {
            isLoading && (
                <MDCard border={false} boxShadow={false} sx={{ minHeight: '88vh'}}>
                    <Box px={4}>
                        <Grid container sx={{ borderBottom: '1px solid rgba(0,0,0,.1)', padding: '2rem 0' }} alignItems={'center'} justifyContent={'space-between'}>
                            <MDTypography sx={{ fontSize: 26 }} fontWeight={'bold'} data-testid="automation-title">
                                <Skeleton width={'163px'} height={'43px'}/>
                            </MDTypography>
                            <Box display={'flex'} gap={1}>
                                <Skeleton width={'122px'} height={'44px'} />
                                <Skeleton width={'195px'} height={'44px'} />
                            </Box>
                        </Grid>
                        <Grid container gap={2} mt={4}>
                            <Box sx={{ width: 200 }}>
                                <Skeleton width={'100%'} height={'44px'} />
                            </Box>
                            <Box sx={{ width: 200 }}>
                                <Skeleton width={'100%'} height={'44px'} />
                            </Box>
                        </Grid>
                    </Box>
                    <Grid container>
                        <AutomationsTable isLoading={isLoading} />
                    </Grid>
                </MDCard>
            )
        }
    </React.Fragment>
  );
}

export default MainLoadingState;