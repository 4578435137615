import Dialog from "@mui/material/Dialog";
import {DialogContent, FormControlLabel, Grid, Radio, RadioGroup, Stack} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "material-ui/components/MDBox";
import {SelectFrom} from "models/order-creation-flow/select-from";
import MDTypography from "material-ui/components/MDTypography";
import RadioSelectWrapper from "components/radio-select-wrapper";
import MDButton from "material-ui/components/MDButton";

interface NewOrderStep1ModalProps {
    show: boolean
    setShow: Function
    selectFrom: SelectFrom
    setSelectFrom: Function
    onNext: Function
    onBack: Function
    onClose: Function
    showSelectFromPremadeDesignOption: boolean
}

function NewOrderStep1Modal({show, setShow, selectFrom, setSelectFrom, onNext, onBack, onClose, showSelectFromPremadeDesignOption}: NewOrderStep1ModalProps) {
    return <Dialog open={show} onClose={() => onClose()}>
        <DialogContent sx={{width: "450px"}}>
            <DialogTitle>
                Create new order
            </DialogTitle>

            <MDBox>
                <RadioGroup
                    aria-labelledby="product-selection-group"
                    defaultValue="Handwritten Card - Medium"
                    name="product-selection"
                >
                    <Grid container gap={"10px"}>
                        <Grid item width={"100%"}>
                            <RadioSelectWrapper selected={selectFrom === SelectFrom.None}>
                                <MDBox pl={"50px"}>
                                    <FormControlLabel
                                        checked={selectFrom === SelectFrom.None}
                                        value={SelectFrom.None}
                                        control={<Radio color={"primary"}/>}
                                        sx={{margin: 0, display: "flex"}}
                                        disableTypography={true}
                                        label={
                                            <MDTypography variant={"h5"}>
                                                New Order
                                            </MDTypography>
                                        }
                                        onClick={() => setSelectFrom(SelectFrom.None)}
                                    />
                                </MDBox>
                            </RadioSelectWrapper>
                        </Grid>

                        <Grid item width={"100%"}>
                            <RadioSelectWrapper selected={selectFrom === SelectFrom.MyTemplates}>
                                <MDBox pl={"50px"}>
                                    <FormControlLabel
                                        checked={selectFrom === SelectFrom.MyTemplates}
                                        value={SelectFrom.MyTemplates}
                                        control={<Radio color={"primary"}/>}
                                        sx={{margin: 0, display: "flex"}}
                                        disableTypography={true}
                                        label={
                                            <MDTypography variant={"h5"}>
                                                My Templates
                                            </MDTypography>
                                        }
                                        onClick={() => setSelectFrom(SelectFrom.MyTemplates)}
                                    />
                                </MDBox>
                            </RadioSelectWrapper>
                        </Grid>

                        {showSelectFromPremadeDesignOption ? <Grid item width={"100%"}>
                                <RadioSelectWrapper selected={selectFrom === SelectFrom.PremadeDesigns}>
                                    <MDBox pl={"50px"}>
                                        <FormControlLabel
                                            checked={selectFrom === SelectFrom.PremadeDesigns}
                                            value={SelectFrom.PremadeDesigns}
                                            control={<Radio color={"primary"}/>}
                                            sx={{margin: 0, display: "flex"}}
                                            disableTypography={true}
                                            label={
                                                <MDTypography variant={"h5"}>
                                                    Select from Free Card Designs
                                                </MDTypography>
                                            }
                                            onClick={() => setSelectFrom(SelectFrom.PremadeDesigns)}
                                        />
                                    </MDBox>
                                </RadioSelectWrapper>
                            </Grid>
                            :
                            null
                        }
                    </Grid>
                </RadioGroup>
            </MDBox>

            <Stack
                direction="row"
                spacing={1}
                marginTop={2}
                justifyContent="flex-end"
            >
                <MDButton
                    color={"light"}
                    onClick={() => onBack(false)}
                >
                    Cancel
                </MDButton>

                <MDButton
                    onClick={() => onNext()}
                >
                    Next
                </MDButton>
            </Stack>
        </DialogContent>
    </Dialog>
}

export default NewOrderStep1Modal