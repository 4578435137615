import {Dialog, DialogTitle, DialogContent, Grid} from "@mui/material";
import MDCard from "material-ui/components/MDCard";
import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import Check2Icon from "assets/icons/check-2";
import CloseIcon from "assets/icons/close";
import MDButton from "material-ui/components/MDButton";
import {useQuery} from "@tanstack/react-query";
import {ApiResponse} from "models/apiResponse";
import {formatNumber, formatPrice} from "helpers/formatters";
import SubscriptionService from "services/subscription";
import { useGlobal } from "context/global-context";

interface MonthlyPricingTiersModalProps {
    show: boolean
    setShow: Function
}

interface SubscriptionPricing {
    id: number
    subscriptionPrice: number
    cardPrices: {
        product: string
        price: number
    }[]
}

function MonthlyPricingTiersModal({show, setShow}: MonthlyPricingTiersModalProps) {
    const {setShowLoader} = useGlobal()

    const {createSubscriptionLink, getSubscriptionPricing} = SubscriptionService()

    const getSubscriptionPricingQuery = useQuery<ApiResponse<SubscriptionPricing[]>>({
        queryFn: getSubscriptionPricing,
        queryKey: ["subscriptionPricing"],
        refetchOnWindowFocus: false
    })

    return <Dialog open={show} onClose={() => setShow(false)} maxWidth={false}>
        <DialogContent sx={{width: "1300px", maxWidth: "unset"}}>
            <Grid container alignItems={"center"} justifyContent={"space-between"} mb={4}>
                <Grid item>
                    <DialogTitle sx={{marginBottom: 0}}>
                        Monthly Pricing Tiers
                    </DialogTitle>
                </Grid>

                <Grid item>
                    <MDBox display={"flex"} onClick={() => setShow(false)}>
                        <CloseIcon/>
                    </MDBox>
                </Grid>
            </Grid>

            <Grid container alignItems={"center"} spacing={3}>
                {getSubscriptionPricingQuery.data?.payload.map((el, index) => {
                    return <Grid item key={index} width={"33%"}>
                        <MDCard border={true} borderColor={"light"} borderRadiusSize={"xl"} boxShadow={false}>
                            <MDBox px={5} py={4}>
                                <Grid container alignItems={"center"} gap={1}>
                                    <Grid item>
                                        <MDTypography sx={{fontSize: 32}} fontWeight={"bold"}>${formatNumber(el.subscriptionPrice)}</MDTypography>
                                    </Grid>

                                    <Grid item>
                                        <MDTypography sx={{fontSize: 12}} color={"light2"} fontWeight={"regular"}>per month</MDTypography>
                                    </Grid>
                                </Grid>

                                {el.cardPrices.map((cardPrice, index2) => {
                                    return <Grid key={`${index}${index2}`} container gap={1} alignItems={"center"} mt={1}>
                                        <Grid item display={"flex"}>
                                            <Check2Icon/>
                                        </Grid>
                                        <Grid item>
                                            <MDTypography sx={{fontSize: 14}} color={"text"} fontWeight={"normal"}>{`${cardPrice.product}: ${formatPrice(cardPrice.price)}`}</MDTypography>
                                        </Grid>
                                    </Grid>
                                })}

                                <MDBox display={"flex"} justifyContent={"center"} mt={3}>
                                    <MDButton
                                        sx={{width: "320px"}}
                                        color={"primary"}
                                        size={"large"}
                                        onClick={() => {
                                            setShowLoader(true)

                                            createSubscriptionLink(el.id).then((response: any) => {
                                                setShowLoader(false)

                                                window.location.href = response.payload
                                            })
                                        }}
                                    >
                                        Subscribe
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                        </MDCard>
                    </Grid>
                })}
            </Grid>

            <MDBox mt={4}>
                <a href={"mailto: billing@lettrlabs.com"}>
                    <MDButton
                        fullWidth={true}
                        size={"large"}
                        color={"primary"}
                    >
                        Contact us for Enterprise Pricing
                    </MDButton>
                </a>
            </MDBox>
        </DialogContent>
    </Dialog>
}

export default MonthlyPricingTiersModal