// @ts-nocheck
import MDButton from "material-ui/components/MDButton";
import MDTypography from "material-ui/components/MDTypography";
import {Grid, Skeleton} from "@mui/material";
import MDBox from "material-ui/components/MDBox";
import MDCard from "material-ui/components/MDCard";
import MailboxIcon from "assets/icons/mailbox";
import CreateOrderIcon from "assets/icons/create-order";
import {useMutation, useQuery} from "@tanstack/react-query";
import TemplateService from "services/template";
import {Template} from "models/template";
import TemplateCard from "components/template-card";
import PremadeDesignsBlock from "components/premade-designs-block";
import PremadeDesignService from "services/premade-design";
import CreateOrderFlow from "components/create-order-flow";
import {useEffect, useState} from "react";
import {PremadeDesign} from "models/premadeDesign";
import {CreateOrderFlowSteps} from "models/order-creation-flow/create-order-flow-steps";
import TwoButtonWithOptionalFormFieldModal from "components/modal-with-two-buttons-and-textfield";
import {Link, useNavigate} from "react-router-dom";
import {Order} from "models/order";
import OrderService from "services/order";
import { useGlobal } from "context/global-context";
import { DesignCategoryData } from "models/designCategoryData";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './styles.scss';

import Slider from "react-slick";

import React from 'react';
import { Box } from "@mui/system";

function HomePage() {
    const {setShowLoader} = useGlobal()
    const {getTemplates, postTemplate} = TemplateService()
    const {getPremadeDesigns} = PremadeDesignService()
    const {postOrder} = OrderService()

    const navigate = useNavigate()

    const [premadeDesigns, setPremadeDesigns] = useState<DesignCategoryData>()

    const [showCreateOrderFromPremadeDesignModal, setShowCreateOrderFromPremadeDesignModal] = useState<boolean>(false)
    const [showCreateNewTemplateModal, setShowCreateNewTemplateModal] = useState<boolean>(false)
    const [newTemplateName, setNewTemplateName] = useState<string>("")

    const [selectedDesign, setSelectedDesign] = useState<PremadeDesign>()

    const createOrderMutation = useMutation({
        mutationFn: () => {
            let o: Order = new Order(0)

            return postOrder(o)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data: any) => {
            setShowLoader(false)
            navigate(`/order/${data.id}`)
        }
    })

    const getTemplatesQuery = useQuery({
        queryKey: ["templates", false],
        queryFn: () => getTemplates()
    })
    
    const { isLoading } = getTemplatesQuery;

    const createNewTemplateMutation = useMutation({
        mutationFn: () => {
            let template = new Template(0);
            template.name = newTemplateName

            return postTemplate(template)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data) => {
            setShowLoader(false)
            setShowCreateNewTemplateModal(false)
            navigate(`/template/${data.id}`)
        }
    })

    var settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 9,
        slidesToScroll: 1,
        centerMode: false,
        variableWidth: false,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 3000,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
              infinite: false,
              dots: false,
              arrows: true 
            }
          },
          {
            breakpoint: 2600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: false,
              dots: false,
              arrows: true 
            }
          },
          {
            breakpoint: 2200,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: false,
              dots: false,
              arrows: true 
            }
          },
          {
            breakpoint: 1700,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: false,
              dots: false,
              arrows: true 
            }
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: false,
              dots: false,
              arrows: true 
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: false,
              dots: false,
              arrows: true 
            }
          }
        ]
    };

    const responsivePremadeDesigns = {
        desktopExtraLarge: {
            breakpoint: {max: 10000, min: 2200},
            items: 6
        },
        desktopLarge: {
            breakpoint: {max: 2200, min: 1900},
            items: 6
        },
        desktopMedium: {
            breakpoint: {max: 1900, min: 1700},
            items: 5
        },
        desktopSmall: {
            breakpoint: {max: 1700, min: 1024},
            items: 4
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };

    useEffect(function () {
        getPremadeDesigns().then((result) => {
            setPremadeDesigns(result)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <MDBox>
        <MDCard color={"white"}>
            <MDBox p={4}>
                <Grid container alignItems={"center"} justifyContent={"space-between"}>
                    <Grid item>
                        <Link to={"/my-templates"}>
                            <MDTypography fontWeight={"bold"} sx={{fontSize: "26px"}} isLink={true} linkHoverColor={"primary"}>My Templates</MDTypography>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Grid container gap={2}>
                            <Grid item>
                                <MDButton
                                    color={"light"}
                                    onClick={() => {
                                        createOrderMutation.mutate()
                                    }}
                                >
                                    <Grid container alignItems={"center"} gap={1}>
                                        <Grid item display={"flex"}>
                                            <MailboxIcon/>
                                        </Grid>
                                        <Grid item>
                                            New Order
                                        </Grid>
                                    </Grid>
                                </MDButton>
                            </Grid>
                            <Grid item>
                                <MDButton
                                    color={"primary"}
                                    onClick={() => {
                                        setShowCreateNewTemplateModal(true)
                                    }}
                                >
                                    <Grid container alignItems={"center"} gap={1}>
                                        <Grid item display={"flex"}>
                                            <CreateOrderIcon/>
                                        </Grid>
                                        <Grid item>
                                            New Template
                                        </Grid>
                                    </Grid>
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <MDBox mt={3}>
                    {
                        isLoading && (
                            
                        <Slider {...settings}>
                            <MDBox style={{ margin: '1.5rem 0', position: 'relative', width: '395px'}}>
                                <Skeleton variant="rounded" width={'100%'} height={260} />
                            </MDBox>
                            <MDBox style={{ margin: '1.5rem 0', width: '395px'}}>
                                <Skeleton variant="rounded" width={'100%'} height={260} />
                            </MDBox>
                            <MDBox style={{ margin: '1.5rem 0', width: '395px'}}>
                                <Skeleton variant="rounded" width={'100'} height={260} />
                            </MDBox>
                            <MDBox style={{ margin: '1.5rem 0', width: '395px'}}>
                                <Skeleton variant="rounded" width={'100'} height={260} />
                            </MDBox>
                        </Slider>
                        )
                    }

                    </MDBox>
                    <Box>
                        { (!isLoading) && getTemplatesQuery.data?.length?
                            <Slider {...settings}>
                                {  getTemplatesQuery.data?.map((template: Template, index: number) => {
                                        return <MDBox p={1} key={index} display={'flex'} justifyContent={'space-evenly'}>
                                            <TemplateCard key={index} template={template} templateList={getTemplatesQuery.data}/>
                                        </MDBox>
                                    })
                                }
                            </Slider>
                            :
                            null
                        }
                    </Box>
            </MDBox>
        </MDCard>

        <MDBox mt={2.5}>
            <MDCard color={"white"}>
                <MDBox p={4}>
                    <MDTypography fontWeight={"bold"} sx={{fontSize: "26px"}}>Free Card Designs</MDTypography>

                    {
                        isLoading && (
                            <MDBox mt={3} display={"flex"} flexDirection={'column'}>
                                <Skeleton variant="rounded" width={100} height={30} sx={{ marginBottom: '1rem'}} />
                                <MDBox sx={{ display: 'flex', gap: 1, paddingLeft: '2rem'}}>
                                    <Skeleton variant="rounded" width={340} height={247} />
                                    <Skeleton variant="rounded" width={340} height={247} />
                                    <Skeleton variant="rounded" width={340} height={247} />
                                    <Skeleton variant="rounded" width={340} height={247} />
                                    <Skeleton variant="rounded" width={340} height={247} />
                                </MDBox>
                            </MDBox>
                        )
                    }
                    
                    {
                        !isLoading && (
                            <MDBox mt={3}>
                                <PremadeDesignsBlock
                                    showFilter={false}
                                    data={premadeDesigns}
                                    designOnClick={(design: PremadeDesign) => {
                                        setSelectedDesign(design)
                                        setShowCreateOrderFromPremadeDesignModal(true)
                                    }}
                                    responsiveSteps={responsivePremadeDesigns}
                                />
                            </MDBox>
                        )
                    }
                </MDBox>
            </MDCard>
        </MDBox>

        <CreateOrderFlow
            show={showCreateOrderFromPremadeDesignModal}
            setShow={setShowCreateOrderFromPremadeDesignModal}
            startAtStep={CreateOrderFlowSteps.Step3}
            design={selectedDesign}
        />

        <TwoButtonWithOptionalFormFieldModal
            open={showCreateNewTemplateModal}
            onClose={() => setShowCreateNewTemplateModal(false)}
            formFieldLabel="Template Name"
            formFieldHelperText="Please name your template, this will help you identify it later"
            formFieldValue={newTemplateName}
            formFieldOnChange={setNewTemplateName}
            primaryButtonText="Create"
            primaryButtonOnClick={() => createNewTemplateMutation.mutate()}
            secondaryButtonText="Cancel"
            secondaryButtonOnClick={() => setShowCreateNewTemplateModal(false)}
        />
    </MDBox>
}

export default HomePage