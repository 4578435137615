import React from 'react';

import { Box } from '@mui/system';
import { List, ListItem, Typography } from '@mui/material';

const CustomHTML: React.FC = () => {
  return (
    <Box sx={{ margin: '1rem 0'}}>
        <Typography variant='body2' sx={{ fontWeight: 'bold'}}>Custom HTML</Typography>
        <List>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1 }}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='body2'>Open your website's HTML files</Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1, margin: '.5rem 0'}}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', gap: 1}}>Find the <b style={{ display: 'flex', alignItems: 'center', gap: 4}}> index.html </b> file or the main HTML file of your website</Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1 }}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', gap: 1}}>Insert the provided script just before the closing <b><pre>{'</head>'}</pre></b> tag</Typography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'flex', gap: 1, margin: '.5rem 0'}}>
                <Box sx={{ width: '7px', height: '7px', background: 'red', borderRadius: '100%'}} />
                <Typography variant='body2' sx={{ display: 'flex', gap: 1}}>Save your changes and upload the updated file to your web server</Typography>
            </ListItem>
        </List>
    </Box>
  );
}

export default CustomHTML;