import { useState } from 'react';
import { CSVBoxButton } from '@csvbox/react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { DeleteOutline, Download, UploadFile } from '@mui/icons-material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';

import { IConversion } from 'newStandard/src/services/conversions/types';
import useConversions from 'newStandard/src/services/conversions';
import { actionColumnProps } from 'components/table/table.utils';
import StyledDataGrid from 'components/styled-data-grid';
import MDButton from 'material-ui/components/MDButton';
import MDCard from 'material-ui/components/MDCard';
import { formatDate } from 'helpers/formatters';
import OpenApiModal from './components/OpenApiModal';

export default function ConversionsPage() {
  const queryClient = useQueryClient();
  const [deleteId, setDeleteId] = useState<number>();
  const [openApiModal, setOpenApiModal] = useState<boolean>(false);
  const { getConversion, deleteConversionById } = useConversions();

  const {
    data: { payload: conversions = [] } = {},
    isLoading,
    isRefetching,
  } = useQuery({ queryKey: ['getConversion'], queryFn: getConversion });

  const { mutate: deleteConversion, isPending: isDeleting } = useMutation({
    mutationFn: (id: number) => deleteConversionById(id),
    onSuccess: () => {
      setDeleteId(undefined);
      queryClient.invalidateQueries({ queryKey: ['getConversion'] });
    },
  });

  const columns: GridColDef<IConversion>[] = [
    { field: 'source', headerName: 'Source', flex: 1 },
    { field: 'fileName', headerName: 'File Name', flex: 1 },
    { field: 'uploadDate', headerName: 'Upload Date', flex: 1, valueFormatter: ({ value }) => formatDate(value) },
    {
      ...actionColumnProps,
      getActions: ({ row }: { row: IConversion }) => [
        <IconButton onClick={() => (window.location.href = row.downloadLink)}>
          <Download />
        </IconButton>,
        <IconButton color="error" onClick={() => setDeleteId(row.id)}>
          <DeleteOutline />
        </IconButton>,
      ],
    },
  ];

  return (
    <MDCard sx={{ p: 2 }}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
        <Typography variant="h4">Conversions</Typography>
        <Box display={'flex'} gap={1}>
          <MDButton color="light" onClick={() => setOpenApiModal(true)}>
            <UploadFile sx={{ mr: 1 }} />
            Open API
          </MDButton>
          <CSVBoxButton
            licenseKey={process.env.REACT_APP_CSVBOX_CONVERSIONS ?? ''}
            user={{ user_id: 'LettrLabsCsvBoxAdmin' }}
            onImport={() => queryClient.invalidateQueries({ queryKey: ['getConversion'] })}
            render={(launch) => (
              <MDButton onClick={launch}>
                <UploadFile sx={{ mr: 1 }} />
                Upload CSV
              </MDButton>
            )}
          />
        </Box>
      </Box>
      <StyledDataGrid
        rows={conversions}
        columns={columns}
        loading={isDeleting || isLoading || isRefetching}
        initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
        pageSizeOptions={[10, 25, 50, 100]}
        disableColumnSelector
        disableColumnPinning
        disableColumnReorder
        pagination
      />
      <Dialog open={!!deleteId} onClose={() => setDeleteId(undefined)}>
        <DialogContent>
          <DialogTitle>Are you sure you want to delete?</DialogTitle>
          <Typography fontSize={16} mt={-1}>
            You're about to delete a conversion. This action cannot be undone, and all matchbacks associated with this
            file will be removed. Do you want to proceed?
          </Typography>
          <Box display="flex" gap={1} mt={2}>
            <MDButton fullWidth color="light" disabled={isDeleting} onClick={() => setDeleteId(undefined)}>
              Cancel
            </MDButton>
            <MDButton
              fullWidth
              disabled={isDeleting}
              onClick={() => (deleteId ? deleteConversion(deleteId) : null)}
            >
              {isDeleting ? <CircularProgress size={18} color="inherit" /> : "Yes, I'm sure"}
            </MDButton>
          </Box>
        </DialogContent>
      </Dialog>
      <OpenApiModal open={openApiModal} onClose={() => setOpenApiModal(false)} />
    </MDCard>
  );
}
