import React, { useState } from 'react';

import { Box } from '@mui/system';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CircularProgress, Typography } from '@mui/material';

import MDButton from 'material-ui/components/MDButton';
import useDataSourcesService from 'services/data-sources';
import { ILeadRevealSource } from 'services/data-sources/data-sources.types';

interface IManageLeadReveal {
  handleRefresh: () => void
  leadRevealData: ILeadRevealSource
}

const ManageLeadReveal: React.FC<IManageLeadReveal> = ({ handleRefresh, leadRevealData }) => {

  const queryClient = useQueryClient();

  const data = leadRevealData?.payload ? JSON.parse(leadRevealData.payload) : null;
  
  const { revokeDataSource } = useDataSourcesService();

  const [loading, setLoading] = useState(false);
  const { mutate: revoke } = useMutation({
    mutationFn: () => revokeDataSource('lead-reveal'),
    onMutate: () => setLoading(true),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAllDataSources'] });
      queryClient.invalidateQueries({ queryKey: ['getLeadRevealSource'] });
      setLoading(false);
      handleRefresh();
    },
  });

  const handleRevoke = () => {
    revoke();
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '2rem' }}>
      <Typography variant="h3" sx={{ margin: '3rem 0 .5rem 0', padding: '0 0 2rem 0', borderBottom: '1px solid rgba(0,0,0,.1)', fontSize: 26 }}>
          Manage LeadReveal
      </Typography>
      <Box sx={{ padding: '2rem 0', display: 'flex' }}>
        <Box sx={{ width: '70%'}}>
          <Typography variant="body2" fontWeight={'bold'}>Website URL</Typography>
          <Typography variant="body2">{data?.WebsiteUrl}</Typography><>{console.log(data)}</>
        </Box>
        <Box sx={{ width: '30%%'}}>
          <Typography variant="body2" fontWeight={'bold'}>Project ID</Typography>
          <Typography variant="body2">{data?.ProjectId}</Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '2rem 0', gap: 2}}>
        <MDButton color='primary' disabled>Update Automation</MDButton>
        <MDButton color='error' disabled={loading} onClick={handleRevoke}  sx={{
          '&:hover': {
            backgroundColor: '#d32f2f',
          },
        }}>
          { loading ? <CircularProgress size={15} color='inherit' /> : 'Revoke'}
        </MDButton>
      </Box>
    </Box>
  );
}

export default ManageLeadReveal;