import React from 'react';

import { Dialog } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { IPlatform } from 'services/automations/automatios.service.types';
import { useAutomationContext } from 'pages/automations/context';

import LeadRevealSetup from './components/LeadRevealSetup';
import AutomationsTriage from './components/AutomationsTriage';
import ZapierInformation from './components/ZapierInformation';
import ShopfyInstructions from './components/ShopfyInstructions';
import KlaviyoInstructions from './components/KlaviyoInstructions';
import useDataSourcesService from 'services/data-sources';
import { Step } from 'pages/automations/context/types';

const SetupModal: React.FC = () => {
    const { modalState, toggleModal, setAutomationToSetup, stepToRender, setStepToRender } = useAutomationContext();
    const { getAllDataSources } = useDataSourcesService();

    const { data } = useQuery({ 
        queryKey: ['getAllDataSources'], 
        queryFn: () => getAllDataSources(), 
        refetchOnWindowFocus: false 
    });

    const getComponentToRender = () => {
        if (!modalState.isSetupModalOpen) return null;

        switch (stepToRender) {
            case 'INITIAL':
                return <AutomationsTriage data={data?.payload} />;
            case 'KLAVIYO':
                return <KlaviyoInstructions platform={data?.payload.find((p: IPlatform) => p.displayName.toUpperCase() === stepToRender)} />;
            case 'LEAD RAVEAL':
                return <LeadRevealSetup />;
            case 'SHOPIFY':
                return <ShopfyInstructions platform={data?.payload.find((p: IPlatform) => p.displayName.toUpperCase() === stepToRender)}/>;
            case 'ZAPIER':
                return <ZapierInformation platform={data?.payload.find((p: IPlatform) => p.displayName.toUpperCase() === stepToRender)}/>;
            default:
                return <AutomationsTriage data={data?.payload}/>;
        }
    };

    return (
        <Dialog
            maxWidth={stepToRender === Step.INITIAL ? 'xl' : 'lg'}
            fullWidth
            open={modalState.isSetupModalOpen}
            onClose={() => { toggleModal('isSetupModalOpen', false); setAutomationToSetup(''); setStepToRender(Step.INITIAL)}}
        >
            { getComponentToRender()}
        </Dialog>
    );
};

export default SetupModal;
