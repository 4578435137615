import React, { useEffect, useState } from 'react';

import { Box } from '@mui/system';
import { Coordinate } from 'models/coordinate';
import { mapContainerStyle } from 'helpers/google-map-helper';
import { getCenterOfPolygon } from "helpers/google-map-helper";
import { useAutomationContext } from 'pages/automations/context';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Dialog,Skeleton,Typography } from '@mui/material';
import { Circle, GoogleMap, LoadScript, OverlayView, Polygon as MapsPolygon } from "@react-google-maps/api";

import MDBox from 'material-ui/components/MDBox';
import colors from "material-ui/theme/base/colors";
import CloseIcon from 'assets/icons/close';
import HailTrace from './components/AutomationInfos/HailTrace';
import StyledCarousel from "components/styled-carousel/styled-carousel";
import DefaultTemplate from './components/AutomationInfos/DefaultTemplate';
import AutomationDetailTable from './components/Table';
import useAutomationsService from 'services/automations';

import './styles.scss';

const AutomationDetailModal: React.FC = () => {
    
    const queryClient = useQueryClient();
    
    const [center, setCenter] = useState<Coordinate>()

    const mapOptions: google.maps.MapOptions = {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeId: 'hybrid',
        disableDefaultUI: true
    }
    
    const { modalState, toggleModal, selectedAutomation, automationDetail, setAutomationDetail } = useAutomationContext();

    const { getAutomationByID } = useAutomationsService();

    const responsiveTemplates = {
        desktopLarge: {
            breakpoint: {max: 3000, min: 1900},
            items: 1
        },
        desktopMedium: {
            breakpoint: {max: 1900, min: 1500},
            items: 1
        },
        desktopSmall: {
            breakpoint: {max: 1500, min: 1200},
            items: 1
        },
        tablet: {
            breakpoint: {max: 1200, min: 900},
            items: 1
        },
        mobile: {
            breakpoint: {max: 900, min: 0},
            items: 1
        }
    };

    const {
        data,
        isLoading
    } = useQuery({ 
        queryKey: ['getAutomationsById',selectedAutomation?.id, selectedAutomation?.integrationOrderType], 
        queryFn: () => getAutomationByID(selectedAutomation?.id ?? 0, selectedAutomation?.integrationOrderType ?? 0), 
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        enabled: Boolean(selectedAutomation?.id && selectedAutomation?.integrationOrderType)
    });

    const handleClose = () => {
        queryClient.invalidateQueries({ queryKey: ['getAutomationsById'] });
        toggleModal('isDetailModalOpen', false);
    }

    const getGeneralInfoComponentToShow = () => {
        switch (selectedAutomation?.integrationOrderType) {
            case 1 :
                return <DefaultTemplate isLoading={isLoading} />
            case 3:
                return <HailTrace isLoading={isLoading} />
            default:
                return <DefaultTemplate isLoading={isLoading} />
        }
    }

    const mapsIsVisible = () => {
        if(automationDetail?.hailTrace?.polygons &&  automationDetail?.hailTrace?.polygons.length > 0) {
            return true
        } else if (automationDetail?.hailTrace?.radii &&  automationDetail?.hailTrace?.radii.length > 0) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if(modalState.isDetailModalOpen) {
          setAutomationDetail(data?.payload);

          if(data?.payload) {
            if (data.payload.hailTrace.radii.length) {
                let centerObject = data.payload.hailTrace.radii[0]
    
                setCenter(centerObject.coordinate)
            }
            else {
                if (data.payload.hailTrace.polygons.length) {
                    let centerObject = data.payload.hailTrace.polygons[0]
    
                    setCenter(getCenterOfPolygon(centerObject.coordinates))
                }
            }
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoading])

    return (
        <Dialog maxWidth={'xl'} fullWidth open={modalState.isDetailModalOpen} onClose={handleClose}>
            <Box sx={{ display: 'flex', position: 'relative', padding: '2rem 2rem 0 2rem' }}>
                <Typography variant="h4" mb={2} pb={2} sx={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.1)' }}>
                    Detail
                </Typography>
                <CloseIcon sx={{ opacity: .6, position: 'absolute', right: 28}} onClick={handleClose} />
            </Box>
            <Box sx={{ padding: '.5rem 2rem 0 2rem', height: '800px' }}>
                <Box>
                    <Box sx={{ display: 'flex', gap: 4 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '33%'}}>
                            <Typography variant='h4' sx={{ marginTop: '.5rem'}}>General Information</Typography>
                            { getGeneralInfoComponentToShow() }
                        </Box>
                        <Box sx={{ 
                            width: '35%', 
                            height: '300px', 
                            margin: '.5rem 1rem 0 1rem', 
                            padding: '0 1rem', 
                            borderRight: '1px solid rgba(0,0,0,.08)',
                            borderLeft: '1px solid rgba(0,0,0,.08)'
                        }}
                        >
                            <Typography variant='h4' sx={{ padding: '0 0 1rem 1.5rem'}}>Template Preview</Typography>
                            {
                                isLoading ? (
                                    <Skeleton width={'455px'} height={'455px'} sx={{ marginTop: '-100px'}} />
                                ) : (
                                    <StyledCarousel
                                        showDots={true}
                                        renderArrowsWhenDisabled={true}
                                        infinite
                                        autoPlay
                                        autoPlaySpeed={4000}
                                        responsive={responsiveTemplates}
                                        slidesToSlide={1}
                                        swipeable={true}
                                        draggable={true}
                                        arrows={false}
                                        className="custom-dots"
                                    >
                                        <Box sx={{ background: 'grey', width: '100%', height: '255px', borderRadius: '5px'}}>
                                            <img
                                                alt='Front Full'
                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}  
                                                src={automationDetail?.thumbs?.sceneFrontFull} />
                                        </Box>
                                        <Box sx={{ background: 'grey', width: '100%', height: '300px', borderRadius: '5px'}}>
                                            <img
                                                alt='Back Full'
                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}  
                                                src={automationDetail?.thumbs?.sceneBackFull} />
                                        </Box>
                                        <Box sx={{ background: 'grey', width: '100%', height: '300px', borderRadius: '5px'}}>
                                            <img
                                                alt='Envole Full'
                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}  
                                                src={automationDetail?.thumbs?.envelopeFull} />
                                        </Box>
                                    </StyledCarousel>
                                )
                            }
                        </Box>
                            <Box sx={{ 
                                width: '33%', 
                                height: '250px', 
                                visibility: mapsIsVisible() ? 'visible' : 'hidden'
                                }}>
                                <Typography variant='h4' sx={{ padding: '.5rem 0 2rem 1.5rem', marginLeft: '-1.5rem'}}>Map View</Typography>
                                <LoadScript googleMapsApiKey="AIzaSyAnZBcPxYjFTJ2p5VykxLnOcqM0gY4uIY0">
                                <GoogleMap
                                        mapContainerStyle={mapContainerStyle}
                                        options={mapOptions}
                                        center={center}
                                        zoom={9}
                                    >
                                        {automationDetail?.hailTrace?.radii.map((address: any, index) => {
                                            return <MDBox key={index}>
                                                <OverlayView
                                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                    getPixelPositionOffset={(width, height) => ({
                                                        x: -(width / 2),
                                                        y: -(height / 2),
                                                    })}
                                                    position={{
                                                        lat: address.coordinate.lat,
                                                        lng: address.coordinate.lng
                                                    }}
                                                >
                                                    <Typography
                                                        whiteSpace={"nowrap"}
                                                        fontWeight={"bold"}
                                                        textAlign={"center"}
                                                        color={colors.white.main}
                                                        sx={{ textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black" }}
                                                    >
                                                        {address.name}
                                                    </Typography>
                                                </OverlayView>

                                                <Circle
                                                    center={{
                                                        lat: address.coordinate.lat,
                                                        lng: address.coordinate.lng
                                                    }}
                                                    radius={address.radius * 1609.34}
                                                    options={{
                                                        fillColor: colors.primary.main,
                                                        fillOpacity: 0.30,
                                                        strokeColor: colors.primary.main,
                                                        strokeOpacity: 0.8
                                                    }}
                                                />
                                            </MDBox>
                                        })}

                                        {automationDetail?.hailTrace?.polygons.map((polygon: any, index) => {
                                            return <MDBox key={index}>
                                                <OverlayView
                                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                    getPixelPositionOffset={(width, height) => ({
                                                        x: -(width / 2),
                                                        y: -(height / 2),
                                                    })}
                                                    position={getCenterOfPolygon(polygon.coordinates)}
                                                >
                                                    <Typography
                                                        whiteSpace={"nowrap"}
                                                        fontWeight={"bold"}
                                                        textAlign={"center"}
                                                        color={colors.white.main}
                                                        sx={{ textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black" }}
                                                    >
                                                        {polygon.name}
                                                    </Typography>
                                                </OverlayView>

                                                <MapsPolygon
                                                    key={polygon.id}
                                                    draggable={false}
                                                    editable={false}
                                                    path={polygon.coordinates}
                                                    options={{
                                                        fillColor: colors.primary.main,
                                                        fillOpacity: 0.40,
                                                        strokeColor: colors.primary.main,
                                                        strokeOpacity: 1,
                                                        strokeWeight: 3
                                                    }}
                                                />
                                            </MDBox>
                                        })}
                                    </GoogleMap>
                                </LoadScript>
                            </Box>
                    </Box>
                    <Box sx={{ margin: '2rem 0 1rem 0'}}>
                        <AutomationDetailTable />
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
}

export default AutomationDetailModal;