export enum DataSourceType {
  SmartAutomation = 'SmartAutomation',
  Movers = 'MoverMail',
}

export enum AuthenticatedStatusType {
  Authenticated = 'Authenticated',
  NotAuthenticated = 'Not Authenticated',
  Pending = 'Pending',
}

export interface IDataSource {
  id: number;
  displayName: string;
  isEnabled: boolean;
  logoImageUrl?: string;
  authenticationStatus: AuthenticatedStatusType;
}

export interface IShopifyStore {
  id: string;
  name: string;
}

export interface ILeadRevealSource {
  oAuthCode: string;
  authenticationStatus?: string;
  payload: string;
  state: string;
}

export interface ILeadRevealGoogleContainer {
  name: string;
  projectId: string;
}

export interface ILeadRevealSourcePayload {
  accountName?: string;
  useServiceAccount: boolean;
  projectId?: string;
  accountContainers?: { account: { name: string } }[];
  websiteUrl: string;
  hasInstalledTagManagerCorrectly?: boolean;
  hasDefaultCreditCard: boolean;
  hasActiveSubscription: boolean;
  monthlyLeads?: number;
  createAntiPixel?: boolean;
  antiPixelPages?: string[];
}

export interface ISetLeadRevealPayload {
  useServiceAccount: boolean;
}
