import React, { useState } from "react";

import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { IAutomation } from "services/automations/automatios.service.types";
import { statusStyles } from "./StatusStyles";
import { DataProviders } from "../enums/DataProviders.enum";
import { useAutomationContext } from "pages/automations/context";
import { List, ListItem, ListItemButton, ListItemText, Popover } from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import useAutomationsService from "services/automations";

export const StatusBall = ({ row }: { row: IAutomation }) => {
  const navigate = useNavigate();

  const { getStatusMessageBeforeUpdate } = useAutomationsService();
  const { selectedAutomation, setSelectedAutomation, setStatusUpdateInfo, statusUpdateInfo, toggleModal } = useAutomationContext();
  
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { color, ballColor } = statusStyles[row.status.toUpperCase()] || { color: 'black', ballColor: 'black' };

  const getMessageMutation = useMutation({
    mutationFn: () => getStatusMessageBeforeUpdate(selectedAutomation?.id ?? 0, selectedAutomation?.integrationOrderType ?? 0, statusUpdateInfo.nextStatus),
    onMutate: () => setStatusUpdateInfo({ ...statusUpdateInfo, loading: true}),
    onSuccess: (data) => {
      setStatusUpdateInfo({ ...statusUpdateInfo, requiresRetroactiveDate: data.payload.requiresRetroactiveDate,  statusMessageToShowBeforeSave: data.payload.message, loading: false });
    },
    onError: (err) => {
      setStatusUpdateInfo({ ...statusUpdateInfo, loading: false})
    },
});

  const isOpen = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectMenu = (menu: any, row: IAutomation) => {
    enum Platforms {
      Shopify = 4,
      Klaviyo = 5,
      LeadReveal = 6,
      Movers = 7
    }

    if(row.status === menu.description) return

    //  ** Redirect Directly to automation edit screen when is Smart Automation **
    if(row.status.toUpperCase() === 'DRAFT' && (row.integrationOrderType === Platforms.Shopify || row.integrationOrderType === Platforms.LeadReveal || row.integrationOrderType === Platforms.Klaviyo || row.integrationOrderType === Platforms.Movers)) handleRedirect(row);

    handleClose();
    setSelectedAutomation(row);
    getMessageMutation.mutate();
    toggleModal('isUpdateStatusModalOpen', true);
    setStatusUpdateInfo({ ...statusUpdateInfo, nextStatus: menu.description });
  }

  const handleRedirect = (rowData: IAutomation) => {
    const id = rowData.id;

    switch (rowData.integrationOrderType) {
        case DataProviders.Movers:
            navigate(`/smart-automation/${id}?source=${4}`);
            break;
        case DataProviders.Klaviyo:
            navigate(`/smart-automation/${id}?source=${2}`);
            break;
        case DataProviders.Shopify:
            navigate(`/smart-automation/${id}?source=${1}`);
            break;
        case DataProviders.LeadReveal:
            navigate(`/smart-automation/${id}?source=${3}`);
            break;
        case DataProviders.HailTrace:
            navigate(`/storm-automation/order/${id}`);
            break;
        case DataProviders.Normal:
            navigate(`/template/${id}`);
            break;
        default:
            console.warn('Unknown integration type');
    }
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '30%',
          alignItems: 'center',
          gap: 1,
          color,
          fontWeight: 500,
          cursor: 'pointer',
        }}
        onClick={handleOpen}
      >
        {row.status.toUpperCase() === 'INACTIVE' ? (
          <Box display={'flex'} gap={1} alignItems={'center'}>
            <PauseCircleIcon sx={{ width: '18px', marginLeft: '-2px', marginRight: '-2px' }} />
          </Box>
        ) : (
          <Box display={'flex'} gap={1} alignItems={'center'}>
            <Box sx={{ width: '13px', height: '13px', borderRadius: '100%', background: ballColor }} />
          </Box>
        )}
        <ExpandMoreIcon sx={{ fontSize: '6px', width: '18px', height: '18px', color: 'grey' }} />
      </Box>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            width: '120px'
          },
        }}
      >
        <List>
          {row.availableStatuses.map((menu, index) => (
            <ListItem key={index} disablePadding sx={{ padding: '0 !important'}}>
              <ListItemButton selected={menu.description === row.status} onClick={() => handleSelectMenu(menu, row)}>
                <ListItemText  
                  primary={menu.description === 'Inactive' ? 'Paused' : menu.description}  
                  sx={{ '& .MuiTypography-root': { fontSize: '.8rem !important', lineHeight: 1 } }} 
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </React.Fragment>
  );
};