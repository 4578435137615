import { useEffect, useState } from 'react';

import { Drawer, List } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import ProfileService from 'services/profile';
import { RouteType } from 'newStandard/src/routes/types';
import sidebarRoutes from 'newStandard/src/routes/sidebar';
import sidebarAdminRoutes from 'newStandard/src/routes/sidebarAdmin';
import sidebarSuperAdminRoutes from 'newStandard/src/routes/sidebarSuperAdmin';

import SidebarCreateItem from './SidebarCreateItem';
import SidebarItem from './SidebarItem';

export const sidebarFullWidth = 90;

export default function Sidebar() {
  const { getLoggedInProfile } = ProfileService();
  const [routes, setRoutes] = useState<RouteType[]>([]);

  const { data: profile } = useQuery({ queryKey: ['loggedInProfile'], queryFn: getLoggedInProfile });

  useEffect(() => {
    let auxRoutes = [...sidebarRoutes];
    if (profile) {
      const email = profile.email.toLowerCase();
      const isAdmin = profile.isAdmin;
      const isSuperAdmin = profile.isSuperAdmin;
      
      if (profile.industry === 'Home Services' || email.endsWith('lettrlabs.com') || isAdmin) {
        const automationGroup = auxRoutes.find((el) => el.name === 'Automations');
        if (automationGroup && automationGroup.collapse) {
          const stormRoute = automationGroup.collapse.find((item) => item.route === '/storm-automation');
          if (stormRoute) stormRoute.hide = false;
        }
      }
      if (isAdmin) {
        auxRoutes.push(...sidebarAdminRoutes);
      }
      if (isSuperAdmin) {
        const superAdminRoutes = { ...sidebarSuperAdminRoutes };
        if (profile.email.toLowerCase() === 'brandon@lettrlabs.com' && Array.isArray(superAdminRoutes.collapse)) {
          const routeIndex = superAdminRoutes.collapse.findIndex((el) => el.route === '/superadmin/discount-approval');
          if (routeIndex >= 0) superAdminRoutes.collapse[routeIndex].hide = false;
        }
        auxRoutes.push(superAdminRoutes);
      }
    }
    setRoutes(auxRoutes);
  }, [profile]);

  return (
    <Drawer variant="permanent">
      <SidebarCreateItem />
      <List>
        {routes.map((route) => (
          <SidebarItem key={route.name} {...route} />
        ))}
      </List>
    </Drawer>
  );
}
